import React from 'react';
import { Row, Col, Form, FormInstance } from 'antd';
import {
    GwFlowPersonalInfoFieldFormValues,
    GwFlowPersonalInfoFieldMap,
} from './GwFlowPersonalInfo';
import {
    GwFlowPersonalInfoFieldType,
    GwFlowPersonalInfoValdationRule,
    DocumentCode,
    GwFlowDocumentCaptureValdationRule,
} from 'gw-api/dist/types';
import { useTranslation } from 'react-i18next';
import CountrySelect from '../GwFlowDocumentTypeSelect/CountrySelect';
import DocumentTypeSelect from '../GwFlowDocumentTypeSelect/DocumentTypeSelect';
import { useConfigContext } from '../ConfigContext';
import moment, { Moment } from 'moment';
import Input from '../../common/Input';
import DatePicker from '../../common/DatePicker';

export default GwFlowPersonalInfoBasicForm;

export type GwFlowPersonalInfoBasicFormProps = {
    form: FormInstance;
    fieldsMap: GwFlowPersonalInfoFieldMap;
    initialValues?: GwFlowPersonalInfoFieldFormValues;
    availableDocuments?: DocumentCode[];
    validations?: GwFlowPersonalInfoValdationRule[];
};

function GwFlowPersonalInfoBasicForm({
    form,
    fieldsMap,
    initialValues,
    availableDocuments,
    validations,
}: GwFlowPersonalInfoBasicFormProps) {
    const { t } = useTranslation();
    const { locale } = useConfigContext();
    return (
        <Row gutter={20} className="GwFlowPersonalInfoBasicForm">
            {Object.values(fieldsMap)
                .filter((field) => field.show)
                .map((field) => {
                    const { type, show, disabled, ...restField } = field;
                    if (type === GwFlowPersonalInfoFieldType.firstName) {
                        return (
                            <Col xs={24} sm={24} md={24} key="firstName">
                                <Form.Item name="firstName" {...restField}>
                                    <Input
                                        placeholder={t('Ingresa tus nombres')}
                                        disabled={
                                            !!initialValues?.firstName &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (type === GwFlowPersonalInfoFieldType.lastName) {
                        return (
                            <Col xs={24} sm={24} md={24} key="lastName">
                                <Form.Item name="lastName" {...restField}>
                                    <Input
                                        placeholder={t('Ingresa tus apellidos')}
                                        disabled={
                                            !!initialValues?.lastName &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.dateOfBirth
                    ) {
                        const {
                            rules: restRules = [],
                            ...restFieldDateOfBirth
                        } = restField;
                        return (
                            <Col xs={24} sm={24} md={24} key="dateOfBirth">
                                <Form.Item
                                    name="dateOfBirth"
                                    {...restField}
                                    rules={[
                                        ...restRules,
                                        {
                                            validator: async (_, value) => {
                                                await validateMinAge(
                                                    value,
                                                    validations,
                                                    t
                                                );
                                                return true;
                                            },
                                        },
                                    ]}
                                >
                                    <DatePicker
                                        format={locale?.lang?.dateFormat}
                                        style={{ width: '100%' }}
                                        placeholder={t(
                                            'Ingresa tu fecha de nacimiento'
                                        )}
                                        disabled={
                                            !!initialValues?.dateOfBirth &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.nationality
                    ) {
                        return (
                            <Col xs={24} sm={24} md={24} key="nationality">
                                <Form.Item name="nationality" {...restField}>
                                    <CountrySelect
                                        size={'middle'}
                                        placeholder={t(
                                            'Ingresa tu nacionalidad'
                                        )}
                                        disabled={
                                            !!initialValues?.nationality &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.documentType
                    ) {
                        return (
                            <Col xs={24} sm={24} md={24} key="documentType">
                                <Form.Item name="documentType" {...restField}>
                                    <DocumentTypeSelect
                                        size={'middle'}
                                        availableDocuments={availableDocuments}
                                        placeholder={t(
                                            'Elige el tipo de documento'
                                        )}
                                        disabled={
                                            !!initialValues?.documentType &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.documentCountry
                    ) {
                        return (
                            <Col xs={24} sm={24} md={24} key="documentCountry">
                                <Form.Item
                                    name="documentCountry"
                                    {...restField}
                                >
                                    <CountrySelect
                                        size={'middle'}
                                        placeholder={t(
                                            'Elige el país del documento'
                                        )}
                                        disabled={
                                            !!initialValues?.documentCountry &&
                                            disabled
                                        }
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.documentNumber
                    ) {
                        const { rules: fieldRules = [], ...restWithoutRules } =
                            restField;
                        return (
                            <Col xs={24} sm={24} md={24} key="documentNumber">
                                {
                                    <Form.Item
                                        shouldUpdate={() => true}
                                        noStyle={true}
                                    >
                                        {() => {
                                            return (
                                                <Form.Item
                                                    name="documentNumber"
                                                    rules={[
                                                        ...fieldRules,
                                                        ...(form.getFieldValue(
                                                            'documentType'
                                                        ) === 'ID_CARD'
                                                            ? [
                                                                  {
                                                                      pattern:
                                                                          /^\d+$/,
                                                                      message:
                                                                          t(
                                                                              'Número de documento inválido'
                                                                          ),
                                                                  },
                                                              ]
                                                            : []),
                                                    ]}
                                                    {...restWithoutRules}
                                                >
                                                    <Input
                                                        placeholder={t(
                                                            'Ingresa tu número de documento'
                                                        )}
                                                        disabled={
                                                            !!initialValues?.documentNumber &&
                                                            disabled
                                                        }
                                                    />
                                                </Form.Item>
                                            );
                                        }}
                                    </Form.Item>
                                }
                            </Col>
                        );
                    } else if (
                        type ===
                        GwFlowPersonalInfoFieldType.documentExpirationDate
                    ) {
                        return (
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                key="documentExpirationDate"
                            >
                                <Form.Item
                                    name="documentExpirationDate"
                                    {...restField}
                                >
                                    <DatePicker
                                        format={locale?.lang?.dateFormat}
                                        style={{ width: '100%' }}
                                        placeholder={t(
                                            'Ingresa la fecha de expiración del documento'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.countryOfResidence
                    ) {
                        return (
                            <Col
                                xs={24}
                                sm={24}
                                md={24}
                                key="countryOfResidence"
                            >
                                <Form.Item
                                    name="countryOfResidence"
                                    {...restField}
                                >
                                    <CountrySelect
                                        size={'middle'}
                                        placeholder={t(
                                            'Elige el país de residencia'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    } else if (
                        type === GwFlowPersonalInfoFieldType.cityOfResidence
                    ) {
                        return (
                            <Col xs={24} sm={24} md={24} key="cityOfResidence">
                                <Form.Item
                                    name="cityOfResidence"
                                    {...restField}
                                >
                                    <Input
                                        placeholder={t(
                                            'Ingresa tu ciudad de residencia'
                                        )}
                                        disabled={disabled}
                                    />
                                </Form.Item>
                            </Col>
                        );
                    }
                })}
        </Row>
    );
}

export async function validateMinAge(
    date: Moment,
    validations?: GwFlowPersonalInfoValdationRule[],
    t?: any
) {
    const minAgeRule: GwFlowDocumentCaptureValdationRule | undefined =
        validations?.find((rule) => rule.minAge);
    if (minAgeRule && minAgeRule?.minAge) {
        const age = moment().diff(moment(date), 'year');
        if (age < minAgeRule?.minAge) {
            throw new Error(
                (minAgeRule?.message && t(minAgeRule?.message)) ||
                    `${t('La edad debe ser mayor a')} ${minAgeRule.minAge} ${t(
                        'años'
                    )}`
            );
        }
    }
}
