import React, { useContext, useMemo, useState } from 'react';
import { GwFlowStepProps } from '../GwFlow';
import {
    DocumentCaptureProvider,
    DocumentCode,
    GwFlowDocumentCaptureOptions,
    GwFlowDocumentCaptureProviderResult,
    GwFlowDocumentCaptureValdationRule,
    UserInfo,
    MicroblinkExtractionResult,
    DocumentInfo,
    GwFlowDocumentCaptureProviderError,
} from 'gw-api/dist/types';
import GwFlowDocumentCaptureMicroblink from './GwFlowDocumentCaptureMicroblink';
import { Modal, Tabs } from 'antd';
import GwFlowDocumentCaptureTips from './GwFlowDocumentCaptureTips';
import { DocumentType } from '@microblink/blinkid-in-browser-sdk';

import { useTranslation } from 'react-i18next';
import GwFlowDocumentCaptureConfirm from './GwFlowDocumentCaptureConfirm';

import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import LoadingWrapper from '../../common/LoadingWrapper';
import { CountryInfo } from 'gw-api/dist/types';
import dayjs from 'dayjs';
import useRetries from '../../common/useRetries';
import { DebugContext } from '../../common/Debug';

export default GwFlowDocumentCapture;

export interface GwFlowDocumentCaptureProvider {
    onlyFront?: boolean;
    onError?: (error: GwFlowDocumentCaptureProviderError) => void;
    onSuccess?: (result: GwFlowDocumentCaptureProviderResult) => void;
    onCancel?: (result: GwFlowDocumentCaptureProviderResult) => void;
}

export interface GwFlowDocumentCaptureProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowDocumentCaptureOptions;
}

function GwFlowDocumentCapture({
    options = {},
    onResult,
    onCancel,
    onError,
}: Partial<GwFlowDocumentCaptureProps>) {
    const { t, i18n } = useTranslation();
    const { logError, logDebug } = useContext(DebugContext);
    const {
        provider = DocumentCaptureProvider.MICROBLINK,
        showTips,
        showPreview,
        enableEditOcr,
        validations,
        retries,
    } = options;
    const { consumeRetry, getRetryError, retriesLeft } = useRetries(retries);

    const [tmpResult, setTmpResult] =
        useState<GwFlowDocumentCaptureProviderResult>();
    const handleTipClick = () => {
        setActiveKey('scan');
    };

    const validateExtraction = (
        extractionResult: MicroblinkExtractionResult
    ) => {
        const minAgeRule = validations?.find((rule) => rule.minAge);
        if (minAgeRule?.minAge) {
            logDebug('Validating age...');
            validateMinAge(minAgeRule, extractionResult, t);
            logDebug('Age is valid.');
        }
        // validateDocumentType(document!, extractionResult, t);
        // validateCountry(country!, extractionResult, t);
        // validateFaceImage(extractionResult, t);
        // if (!isPassport) {
        //     validateMatchFrontBack(extractionResult, t);
        // }
    };
    const handleCaptureSuccess = async (
        result: GwFlowDocumentCaptureProviderResult
    ) => {
        try {
            await validateExtraction(result.rawScanResult!);
        } catch (err) {
            logError(err);
            logDebug(`Retries left: ${retriesLeft}`);
            Modal.confirm({
                content: <div>{err?.message}</div>,
                cancelText: t('Cancelar'),
                onCancel: () => {
                    onResult?.({
                        status: 'canceled',
                        statusMessage: err.message,
                    });
                },
                onOk: () => {
                    if (consumeRetry()) {
                        setActiveKey('idle');
                        setTimeout(() => {
                            setActiveKey('scan');
                        });
                        logDebug(`Retrying document capture`);
                    } else {
                        logDebug(`No more retries left`);
                        onResult?.({
                            status: 'warning',
                            statusMessage: getRetryError()!.message,
                        });
                    }
                },
                okText: t('Reintentar'),
            });
            return;
        }

        delete result.rawScanResult;

        if (showPreview) {
            setTmpResult(result);
            setActiveKey('preview');
        } else {
            onResult?.(result);
        }
    };
    const handleCaptureError = (error: any) => {
        if (showTips) {
            setActiveKey('tips');
        }
        onError?.(error);
    };
    const handleCaptureCancel = () => {
        if (showPreview) {
            setActiveKey('tips');
        } else {
            onCancel?.();
        }
    };

    const handleCaptureConfirm = (newUserInfo?: UserInfo) => {
        let result = { ...tmpResult };
        if (enableEditOcr && newUserInfo) {
            result = { ...result, userInfo: newUserInfo };
        }
        onResult?.(result);
    };
    const handleCaptureRetake = () => {
        setActiveKey('scan');
    };
    const items = useMemo(
        () => [
            ...(showTips
                ? [
                      {
                          label: '',
                          key: 'tips',
                          children: (
                              <StepContent
                                  header={<TitleText size="large"></TitleText>}
                                  body={
                                      <GwFlowDocumentCaptureTips
                                          onClick={handleTipClick}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
            {
                label: '',
                key: 'scan',
                children:
                    provider === DocumentCaptureProvider.MICROBLINK ? (
                        <GwFlowDocumentCaptureMicroblink
                            locale={i18n}
                            onSuccess={handleCaptureSuccess}
                            onError={handleCaptureError}
                            onCancel={handleCaptureCancel}
                        />
                    ) : (
                        'Unknown provider'
                    ),
            },
            ...(showPreview
                ? [
                      {
                          label: '',
                          key: 'preview',
                          children: (
                              <StepContent
                                  header={<TitleText size="large"></TitleText>}
                                  body={
                                      <GwFlowDocumentCaptureConfirm
                                          result={tmpResult!}
                                          onConfirm={handleCaptureConfirm}
                                          onCancel={handleCaptureRetake}
                                          confirmText={t('Aceptar')}
                                          cancelText={t(
                                              'Tomar documento de nuevo'
                                          )}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),

            {
                label: '',
                key: 'idle',
                children: <LoadingWrapper />,
            },
        ],
        [
            options,
            handleTipClick,
            handleCaptureSuccess,
            handleCaptureError,
            handleCaptureCancel,
        ]
    );

    const [activeKey, setActiveKey] = useState<string>(items[0].key);
    return (
        <div className="GwFlowDocumentCapture" style={{ margin: 0 }}>
            <Tabs
                items={items}
                renderTabBar={() => <span></span>}
                activeKey={activeKey}
                destroyInactiveTabPane={true}
            />
        </div>
    );
}

function documentTypeToBlinkDocumentType(document: DocumentInfo) {
    switch (document.code) {
        case DocumentCode.ID_CARD:
            return DocumentType.ID;
        case DocumentCode.PASSPORT:
            return DocumentType.PASSPORT;
        case DocumentCode.DRIVING_LICENSE:
            return DocumentType.DL;
        default:
            return undefined;
    }
}

//@TODO refactor this function to reuse validation conde from Personal Info step
export function validateMinAge(
    minAgeRule: GwFlowDocumentCaptureValdationRule,
    result: any,
    t?: any
) {
    const formattedDate = `${result.dateOfBirth.year}-${String(
        result.dateOfBirth.month
    ).padStart(2, '0')}-${String(result.dateOfBirth.day).padStart(2, '0')}`;

    const age = dayjs().diff(dayjs(formattedDate), 'year');
    if (age < minAgeRule.minAge) {
        throw new Error(
            t(minAgeRule.message) ||
                `${t('La edad debe ser mayor a')} ${minAgeRule.minAge} ${t(
                    'años'
                )}`
        );
    }
}
function validateDocumentType(document: DocumentInfo, result: any, t: any) {
    if (!result.classInfo.documentType) {
        throw new Error(t('El tipo de documento no pudo ser capturado'));
    }
    if (
        documentTypeToBlinkDocumentType(document) !==
        result.classInfo.documentType
    ) {
        throw new Error(t('El tipo del documento no es correcto'));
    }
}

function validateCountry(country: CountryInfo, result: any, t: any) {
    if (!result.classInfo.isoAlpha3CountryCode) {
        throw new Error(t('El pais del documento no pudo ser capturado'));
    }
    if (country.alpha3 !== result.classInfo.isoAlpha3CountryCode) {
        throw new Error(t('El país del documento no es correcto'));
    }
}

function validateFaceImage(result: any, t: any) {
    if (result.faceImage.rawImage === null) {
        throw new Error(t('El documento es invalido'));
    }
}

function validateMatchFrontBack(result: any, t: any) {
    // if (result.dataMatch !== DataMatchState.Success) {
    //     throw new Error(t('El dorso del documento no coincide con el frente'));
    // }
}
