import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DebugContext } from '../../common/Debug';
import TitleText from '../../common/TitleText';
import StepContent from '../../common/StepContent';
import GwService from '../../services/GwService';
import { Form, Button as AntButton } from 'antd';
import LoadingWrapper from '../../common/LoadingWrapper';
import OtpInput from '../../common/OtpInput';
import { GwFlowOtpType } from './GwFlowOtp';
import Space from '../../common/Space';
import { useCountDown } from '../../common/useCountDown';

export interface GwFlowValidateSendFormValues {
    code: string;
}
export default GwFlowValidateSend;

export type GwFlowValidateSendProps = {
    otpType?: GwFlowOtpType;
    email?: string;
    phone?: string;
    onSuccess: (result: any) => void;
    onError: (error: any) => void;
    onCancel: () => void;
    resendTimeout?: number;
    readonly?: boolean;
    disabled?: boolean;
};

function GwFlowValidateSend({
    otpType,
    email,
    phone,
    onSuccess,
    onError,
    resendTimeout = 9,
    onCancel,
    readonly,
    disabled,
}: GwFlowValidateSendProps) {
    const { t } = useTranslation();
    const { logError, logDebug } = useContext(DebugContext);
    const [sending, setSending] = useState<boolean>(true);
    const [waiting, setWaiting] = useState<boolean>(false);
    const [verifying, setVerifying] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [form] = Form.useForm<GwFlowValidateSendFormValues>();
    const timer = useRef<any>(null);
    const [enableResend, setEnableResend] = useState<boolean>(false);
    const [counter, startCountdown] = useCountDown(resendTimeout, 1000);

    const isSms = otpType === 'sms';
    const send = async () => {
        try {
            logDebug(`Sending ${otpType}`);
            setSending(true);
            if (otpType === 'sms') {
                await GwService.sendOtpSms({ recipient: phone! });
            } else {
                await GwService.sendOtpEmail({ recipient: email! });
            }
            setEnableResend(false);
            startCountdown();
            setSending(false);
            setWaiting(true);
            timer.current = setTimeout(() => {
                setEnableResend(true);
            }, resendTimeout);
        } catch (error) {
            logError(error);
            setSending(false);
            onError(error);
        }
    };
    const verify = async ({ code }) => {
        try {
            if (Number.isNaN(Number.parseInt(code))) {
                logError('Invalid code');
                throw new Error(t('El código ingresado no es numerico'));
            }
            setVerifying(true);
            let response;
            if (otpType === 'sms') {
                response = await GwService.validateOtpSms({
                    recipient: phone!,
                    otp: code,
                });
            } else {
                response = await GwService.validateOtpEmail({
                    recipient: email!,
                    otp: code,
                });
            }
            setEnableResend(false);
            setVerifying(false);
            setWaiting(false);
            setSuccess(true);
            onSuccess?.(response);
        } catch (error) {
            logError(error);
            setVerifying(false);
            form.resetFields(['code']);
            onError(error);
        }
    };
    useEffect(() => {
        (async () => {
            await send();
        })();
        return () => {
            clearTimeout(timer.current);
        };
    }, []);

    const handleVerify = async (formValues: GwFlowValidateSendFormValues) => {
        await verify(formValues);
    };
    const handleValuesChange = (values: GwFlowValidateSendFormValues) => {
        if (!Number.isNaN(Number.parseInt(values.code))) {
            if (values.code.length === 6) {
                setTimeout(() => {
                    verify(values);
                }, 0);
            }
        }
    };
    const handleResendClick = async () => {
        send();
    };
    return (
        <div className="GwFlowValidateSend">
            {sending ? (
                <StepContent
                    header={
                        <TitleText size="large">
                            {isSms
                                ? t('Enviando SMS con código de verificacion')
                                : t(
                                      'Enviando correo con código de verificacion'
                                  )}
                        </TitleText>
                    }
                    body={<LoadingWrapper />}
                />
            ) : waiting ? (
                <StepContent
                    header={
                        <Space direction="vertical">
                            <TitleText size="large">
                                {t('Ingresa el codigo de verificación')}
                            </TitleText>
                            <span>
                                {t('Hemos enviado un codigo a')}{' '}
                                {email || phone}
                            </span>
                        </Space>
                    }
                    body={
                        <LoadingWrapper loading={verifying}>
                            <div style={{ maxWidth: '500px', margin: 'auto' }}>
                                <Form<GwFlowValidateSendFormValues>
                                    form={form}
                                    onFinish={handleVerify}
                                    onValuesChange={handleValuesChange}
                                >
                                    <Space
                                        size={70}
                                        wide={true}
                                        direction="vertical"
                                    >
                                        <Form.Item name="code">
                                            <OtpInput
                                                length={6}
                                                disabled={verifying}
                                            />
                                        </Form.Item>
                                        <Space
                                            size={20}
                                            wide={true}
                                            justify="space-between"
                                            direction="vertical"
                                        >
                                            <span>
                                                <span>
                                                    {t(
                                                        '¿No haz recibido el codigo?'
                                                    )}
                                                </span>
                                                <AntButton
                                                    disabled={!enableResend}
                                                    size="small"
                                                    type="link"
                                                    onClick={handleResendClick}
                                                >
                                                    <Space>
                                                        {counter !== 0 ? (
                                                            <span>
                                                                {counter}
                                                            </span>
                                                        ) : null}
                                                        <span>
                                                            {t('Reenviar')}
                                                        </span>
                                                    </Space>
                                                </AntButton>
                                            </span>
                                            {!readonly && !disabled ? (
                                                <AntButton
                                                    size="small"
                                                    type="link"
                                                    onClick={onCancel}
                                                >
                                                    {isSms
                                                        ? t(
                                                              'Probar con otro numero de telefono'
                                                          )
                                                        : t(
                                                              'Probar con otro email'
                                                          )}
                                                </AntButton>
                                            ) : (
                                                <div></div>
                                            )}
                                        </Space>
                                    </Space>
                                </Form>
                            </div>
                        </LoadingWrapper>
                    }
                    bodyProps={{ style: { textAlign: 'center' } }}
                />
            ) : success ? (
                <StepContent
                    header={
                        <TitleText size="large">
                            {t('Codigo verificado correctamente')}
                        </TitleText>
                    }
                    body={<div></div>}
                />
            ) : null}
        </div>
    );
}
