import React, { useContext, useMemo, useState } from 'react';
import { GwFlowStepProps } from '../GwFlow';
import { Tabs } from 'antd';
import GwFlowFaceCaptureTips from './GwFlowFaceCaptureTips';
import { useTranslation } from 'react-i18next';
import GwFlowFaceCaptureConfirm from './GwFlowFaceCaptureConfirm';
import GwFlowFaceCaptureIdrnd, {
    IdrndExtractionResult,
} from './GwFlowFaceCaptureIdrnd';
import GwService from '../../services/GwService';
import LoadingWrapper from '../../common/LoadingWrapper';
import BackButtonWrapper from '../../common/BackButtonWrapper';
import StepContent from '../../common/StepContent';
import useRetries from '../../common/useRetries';
import { DebugContext } from '../../common/Debug';
import {
    FaceCaptureProvider,
    GwFlowFaceCaptureOptions,
    GwFlowFaceCaptureProviderResult,
} from 'gw-api/dist/types';
import './GwFlowFaceCapture.css';
import { displayErrorWithRetry } from '../../common/ErrorModal';

export default GwFlowFaceCapture;

export interface GwFlowFaceCaptureProviderError {}

export interface GwFlowFaceCaptureProvider {
    onlyFront?: boolean;
    locale?: string;
    onError?: (error: GwFlowFaceCaptureProviderError) => void;
    onSuccess?: (result: GwFlowFaceCaptureProviderResult) => void;
    onCancel?: () => void;
}

export interface GwFlowFaceCaptureProps
    extends Omit<GwFlowStepProps, 'options'> {
    options?: GwFlowFaceCaptureOptions;
}

function GwFlowFaceCapture({
    options = {},
    onResult,
    onCancel,
    onError,
}: Partial<GwFlowFaceCaptureProps>) {
    const { t, i18n } = useTranslation();
    const { logDebug, logWarning } = useContext(DebugContext);

    const [loading, setLoading] = useState<boolean>(false);
    const {
        provider = 0,
        showTips,
        showPreview,
        checkLiveness,
        cropFactor,
        retries,
    } = options;
    const { consumeRetry, getRetryError, retriesLeft } = useRetries(retries);

    const [tmpResult, setTmpResult] =
        useState<GwFlowFaceCaptureProviderResult>();
    const handleTipClick = () => {
        setActiveKey('scan');
    };

    const validateExtraction = (extractionResult: any) => {
        // validateDocumentType(document!, extractionResult, t);
        // validateCountry(country!, extractionResult, t);
        // validateFaceImage(extractionResult, t);
        // if (!isPassport) {
        //     validateMatchFrontBack(extractionResult, t);
        // }
    };
    const handleCaptureSuccess = async (
        result: GwFlowFaceCaptureProviderResult
    ) => {
        let resultToSend = { ...result };
        try {
            setLoading(true);
            let livenessResult;
            if (checkLiveness) {
                logDebug('Checking liveness...');
                setActiveKey('liveness');
                setLoading(true);
                const livenessResponse = await GwService.captureLiveness({
                    encryptedFile: result.encryptedFile as string,
                });
                livenessResult = livenessResponse.result;
            }
            await validateExtraction(result.rawFaceCapture);
            resultToSend = { ...resultToSend, livenessResult };

            setLoading(false);
            if (showPreview) {
                setTmpResult(resultToSend);
                setActiveKey('preview');
            } else {
                onResult?.(resultToSend);
            }
        } catch (err) {
            logWarning(err.message);
            logDebug(`Retries left: ${retriesLeft}`);
            if (consumeRetry()) {
                displayErrorWithRetry(
                    err,
                    {
                        icon: null,
                        title: getLivenessErrorTitle(err, t),
                        content: getLivenessErrorSubtitle(err, t),
                        onCancel: () => {
                            logWarning(`User canceled the liveness check`);
                            onResult?.({
                                ...resultToSend,
                                status: 'warning',
                                statusMessage:
                                    'User canceled the liveness check',
                            });
                        },
                        onRetry: () => {
                            logDebug(`Retrying face scan`);
                            setLoading(false);
                            setActiveKey('scan');
                        },
                    },
                    t
                );
            } else {
                logDebug(`No more retries left`);
                onResult?.({
                    ...resultToSend,
                    status: 'warning',
                    statusMessage: err.message,
                });
            }
        }
    };
    const handleCaptureError = (error: any) => {
        onError?.(error);
    };
    const handleCaptureCancel = () => {
        if (showPreview) {
            setActiveKey('tips');
        } else {
            onCancel?.();
        }
    };

    const handleCaptureConfirm = () => {
        onResult?.(tmpResult!);
    };

    const handleBackClick = () => {
        const currentIndex = items.findIndex((item) => item.key === activeKey);
        if (currentIndex === 0) {
            onCancel?.();
        } else {
            const newIndex = currentIndex - 1;
            setActiveKey(items[newIndex].key);
        }
    };
    const handleCaptureRetake = () => {
        setActiveKey('scan');
        logDebug(`Retrying face scan`);
    };
    const items = useMemo(
        () => [
            ...(showTips
                ? [
                      {
                          label: '',
                          key: 'tips',
                          children: (
                              <StepContent
                                  body={
                                      <GwFlowFaceCaptureTips
                                          onClick={handleTipClick}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
            {
                label: '',
                key: 'scan',
                children: (
                    <div className={'GwFlowFaceCapture__scan'}>
                        <BackButtonWrapper
                            loading={false}
                            onBackClick={handleBackClick}
                        >
                            {provider === FaceCaptureProvider.IDRND ? (
                                <GwFlowFaceCaptureIdrnd
                                    locale={i18n.language}
                                    onSuccess={handleCaptureSuccess}
                                    onError={handleCaptureError}
                                    onCancel={handleCaptureCancel}
                                />
                            ) : (
                                'Unknown provider'
                            )}
                        </BackButtonWrapper>
                    </div>
                ),
            },
            ...(checkLiveness
                ? [
                      {
                          label: '',
                          key: 'liveness',
                          children: (
                              <StepContent
                                  body={<LoadingWrapper loading={loading} />}
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
            ...(showPreview
                ? [
                      {
                          label: '',
                          key: 'preview',
                          children: (
                              <StepContent
                                  body={
                                      <GwFlowFaceCaptureConfirm
                                          result={tmpResult!}
                                          onConfirm={handleCaptureConfirm}
                                          onCancel={handleCaptureRetake}
                                          confirmText={t('Aceptar')}
                                          cancelText={t('Tomar de nuevo')}
                                      />
                                  }
                              ></StepContent>
                          ),
                      },
                  ]
                : []),
        ],
        [
            options,
            handleTipClick,
            handleCaptureSuccess,
            handleCaptureError,
            handleCaptureCancel,
        ]
    );

    const [activeKey, setActiveKey] = useState<string>(items[0].key);

    return (
        <div className="GwFlowFaceCapture">
            <Tabs
                items={items}
                renderTabBar={() => <span></span>}
                activeKey={activeKey}
                destroyInactiveTabPane={true}
            />
        </div>
    );
}

export function getLivenessErrorTitle(error: any, t: any): string {
    if (error?.message.includes('[Liveness]')) {
        return t('No pudimos procesar tu selfie');
    } else {
        return t('Ha ocurrido un error');
    }
}
export function getLivenessErrorSubtitle(error: any, t: any): React.ReactNode {
    if (error?.message.includes('[Liveness]')) {
        return (
            <p>
                <br />
                {t('Por favor, sigue estas indicaciones para tomar una nueva')}:
                <ul>
                    <li>
                        {t(
                            'Asegúrate de que tu rostro esté completamente visible y bien iluminado.'
                        )}
                    </li>
                    <li>
                        {t(
                            'No uses objetos que cubran tu rostro, como gafas o sombreros.'
                        )}
                    </li>
                    <li>
                        {t(
                            'Asegúrate de que la cámara esté al nivel de tus ojos.'
                        )}
                    </li>
                    <li>
                        {t('Evita los fondos muy iluminados o muy oscuros.')}
                    </li>
                    <li>
                        {t(
                            'Mantén una expresión neutral y mira directamente a la cámara.'
                        )}
                    </li>
                </ul>
            </p>
        );
    } else {
        return t('Ha ocurrido un error');
    }
}
