import React, { useMemo } from 'react';
import './DocumentTypeSelect.css';
import Select, { SelectProps } from '../../common/Select';
import documents from '../../data/documents.json';
import { useTranslation } from 'react-i18next';
import { DocumentCode, DocumentInfo } from 'gw-api/dist/types';

const documentList = Object.values(documents) as DocumentInfo[];
export default DocumentTypeSelect;

export type DocumentTypeSelectProps = SelectProps & {
    availableDocuments?: DocumentCode[];
};

function DocumentTypeSelect({
    availableDocuments,
    size,
    ...rest
}: DocumentTypeSelectProps) {
    const { t } = useTranslation();
    const documentsToShow = useMemo(() => documentList, []);

    return (
        <div className="DocumentTypeSelect">
            <Select
                style={{
                    minWidth: '300px',
                    width: '100%',
                    textAlign: 'left',
                }}
                options={documentsToShow.map((item) => ({
                    key: item.code,
                    value: item.code,
                    label: t(item.name),
                    disabled: !(
                        availableDocuments === undefined ||
                        (Array.isArray(availableDocuments) &&
                            availableDocuments.includes(item.code))
                    ),
                }))}
                placeholder={t('Elige el tipo de documento')}
                {...rest}
            />
        </div>
    );
}
