import React, { useContext, useEffect, useMemo, useState } from 'react';
import GwFlow from './components/GwFlow';
import {
    GwConfigStep,
    GwConfigStepOptions,
    GwConfigStepType,
    ClientParamsGwConfig,
    GwFlowGlobalResult,
} from 'gw-api/dist/types';
import {
    GwFlowDocumentTypeOptions,
    DocumentCaptureProvider,
    GwFlowDocumentCaptureOptions,
    GwFlowPersonalInfoOptions,
    FaceCaptureProvider,
    GwFlowFaceCaptureOptions,
    GwFlowOtpOptions,
    GwFlowResultOptions,
    GwFlowWelcomeOptions,
    GwFlowValidateOptions,
    GwFlowUploadsOptions,
} from 'gw-api/dist/types';
import { submitForm } from './helpers';
import GwService from './services/GwService';
import { useTranslation } from 'react-i18next';
import { DebugContext } from './common/Debug';
import { useConfigContext } from './components/ConfigContext';
import ProcessFinishedMessage from './ProcessFinishedMessage';
import ContentBox from './common/ContentBox';
import Space from './common/Space';
import Button from './common/Button';
import { Spin } from 'antd';
import SessionTransferedMessage from './SessionTransferedMessage';
import SessionExpiredMessage from './SessionExpiredMessage';
import TitleText from './common/TitleText';
import StepContent from './common/StepContent';
import 'idlive-face-capture-web-development';
import { loadBlinkIdModule } from './components/GwFlowDocumentCapture/GwFlowDocumentCaptureMicroblink';

export default GwFlowApp;

export type GwFlowDefaultValues = {
    userEmail?: string;
    userPhone?: string;
};

export type GwFlowAppProps = {
    defaultValues?: GwFlowDefaultValues;
    overrideflowConfig?: GwConfigStep<GwConfigStepOptions>[];
    activeStepIndex?: number;
    flowInOtherDevice?: boolean;
    resultAlreadySent?: boolean;
    defaultSessionExpired?: boolean;
    initialResult?: GwFlowGlobalResult;
    style?: React.CSSProperties;
};

function GwFlowApp({
    defaultValues,
    overrideflowConfig,
    activeStepIndex,
    flowInOtherDevice,
    resultAlreadySent,
    defaultSessionExpired,
    initialResult,
}: GwFlowAppProps) {
    const { t } = useTranslation();

    const { logError, logDebug } = useContext(DebugContext);
    const [updating, setUpdating] = useState<boolean>(false);
    const {
        config,
        defaultFaceCaptureProvider,
        defaultDocumentCaptureProvider,
        transactionId,
    } = useConfigContext();

    let retryCount = 0;

    const [sessionExpired, setSessionExpired] = useState(defaultSessionExpired);
    const [mobileConnected, setMobileConnected] = useState(flowInOtherDevice);
    const [connectionOpen, setConnectionOpen] = useState(false);
    const [updateError, setUpdateError] = useState(false);

    useEffect(() => {
        loadBlinkIdModule();
    }, []);
    useEffect(() => {
        if (transactionId) {
            // Create a new EventSource connected to your server's endpoint

            logDebug('Initialized');
            const eventSource = new EventSource(
                GwService.getEventsUrl(transactionId),
                {
                    withCredentials: true,
                }
            );

            eventSource.onopen = (event) => {
                setConnectionOpen(true);
            };

            // Define a handler for incoming messages
            eventSource.onmessage = (event) => {
                console.log(event);
            };
            eventSource.onerror = (event) => {
                console.error('An error occurred:', event);
                // You may want to add additional logic here, such as reconnecting or notifying the user.
            };

            eventSource.addEventListener('MOBILE_CONNECTED', (event) => {
                logDebug('Mobile connected');
                setMobileConnected(true);
            });

            eventSource.addEventListener('RESULT_SENT', (event) => {
                finishProcess({
                    [GwConfigStepType.RESULT]: JSON.parse(event.data),
                });
            });

            eventSource.addEventListener('SESSION_EXPIRED', (event) => {
                logDebug('Session expired');
                setSessionExpired(true);
            });
            // Close the connection when the component is unmounted
            return () => {
                eventSource.close();
            };
        }
    }, [transactionId]);

    async function updateWithRetry({
        index,
        result,
    }: {
        index: number;
        result: any;
    }) {
        try {
            setUpdating(true);
            await GwService.update({ index, result });
            setUpdating(false);
            logDebug(`Update result success`);
        } catch (error) {
            if (retryCount < 10) {
                retryCount++;
                logDebug(`Update result failed... Retrying`);
                await delay(1000); // Delay for 1 second before retrying
                await updateWithRetry({ index, result }); // Retry the update operation recursively
            } else {
                setUpdating(false);
                logError(error);
                setUpdateError(true);
            }
        }
    }

    // Helper function to create a delay using Promises
    function delay(ms: number) {
        return new Promise((resolve) => setTimeout(resolve, ms));
    }
    const handlePartialResult = async (
        result: any,
        step: any,
        index: number
    ) => {
        if (
            !result[GwConfigStepType.VALIDATE] &&
            !result[GwConfigStepType.RESULT]
        ) {
            await updateWithRetry({ index, result });
        }
    };

    const finishProcess = async (result?: any) => {
        if (result?.[GwConfigStepType.RESULT]) {
            await logDebug('Logging out');
            await GwService.logout();
        }
        submitForm(
            {
                transactionId:
                    result?.[GwConfigStepType.RESULT]?.transactionId ||
                    transactionId,
            },
            config?.resultConfig.redirectUrl as string,
            config?.resultConfig.redirectMethod as string
        );
    };

    const handleFinish = async (result?: any) => {
        await finishProcess(result);
    };
    const steps = useMemo(() => {
        if (config) {
            return (
                overrideflowConfig ||
                getFlow(
                    config!,
                    defaultDocumentCaptureProvider,
                    defaultFaceCaptureProvider,
                    defaultValues
                )
            );
        } else {
            return [];
        }
    }, []);

    console.log('steps', steps);

    return (
        <div className="GwFlowApp">
            <img src={'goboarding.png'} style={{ width: '125px' }} />
            {updateError ? (
                <ContentBox>
                    <StepContent
                        header={
                            <TitleText size="large">{t('Error')}</TitleText>
                        }
                        body={
                            <Space direction="vertical" align="center">
                                <Space
                                    style={{
                                        fontSize: '96px',
                                        color: config?.uiConfig?.primaryColor,
                                    }}
                                ></Space>
                                {t(
                                    'An error has ocurred and process cannot continue'
                                )}
                                <Button onClick={() => handleFinish()}>
                                    Cerrar
                                </Button>
                            </Space>
                        }
                    />
                </ContentBox>
            ) : sessionExpired ? (
                <ContentBox style={{ position: 'relative' }}>
                    <SessionExpiredMessage
                        extra={
                            <Space>
                                <Button onClick={() => handleFinish()}>
                                    Cerrar
                                </Button>
                            </Space>
                        }
                    />
                </ContentBox>
            ) : resultAlreadySent ? (
                <ContentBox style={{ position: 'relative' }}>
                    <ProcessFinishedMessage
                        extra={
                            <Space>
                                <Button
                                    onClick={async () => {
                                        try {
                                            await GwService.logout();
                                        } catch (error) {}
                                        window.location.reload();
                                    }}
                                >
                                    Cerrar
                                </Button>
                            </Space>
                        }
                    />
                </ContentBox>
            ) : mobileConnected ? (
                <SessionTransferedMessage />
            ) : (
                <Spin spinning={updating}>
                    <GwFlow
                        onPartialResult={handlePartialResult}
                        onFinish={handleFinish}
                        config={{
                            steps,
                        }}
                        activeStepIndex={activeStepIndex}
                        flowInOtherDevice={flowInOtherDevice}
                        forceMobile={config?.clientDataConfig?.mobileOnly}
                        enableMobile={connectionOpen}
                        initialResult={initialResult}
                    />
                </Spin>
            )}
        </div>
    );
}

const DEFAULT_STEP_OPTIONS = {
    [GwConfigStepType.WELCOME]: {},
    [GwConfigStepType.OTP_EMAIL]: {
        useGeolocation: false,
    },
    [GwConfigStepType.OTP_SMS]: {
        useGeolocation: false,
    },
    [GwConfigStepType.DOCUMENT_TYPE_SELECT]: {},
    [GwConfigStepType.DOCUMENT_CAPTURE]: {
        showTips: true,
        showPreview: true,
    },
    [GwConfigStepType.SELFIE_CAPTURE]: {
        showTips: true,
        showPreview: true,
        cropFactor: 1.6,
    },
    [GwConfigStepType.PERSONAL_INFO]: {
        fullFields: true,
        useGeolocation: false,
    },
    [GwConfigStepType.VALIDATE]: {},
    [GwConfigStepType.RESULT]: {},
};

export function getFlow(
    gwConfig: ClientParamsGwConfig,
    defaultDocumentCaptureProvider?: DocumentCaptureProvider,
    defaultFaceCaptureProvider?: FaceCaptureProvider,
    defaultValues?: GwFlowDefaultValues
): GwConfigStep<GwConfigStepOptions>[] {
    const { formConfig, clientDataConfig } = gwConfig;

    let flowConfig = [
        GwConfigStepType.WELCOME,
        GwConfigStepType.OTP_EMAIL,
        GwConfigStepType.DOCUMENT_TYPE_SELECT,
        GwConfigStepType.DOCUMENT_CAPTURE,
        GwConfigStepType.PERSONAL_INFO,
        GwConfigStepType.UPLOADS,
        GwConfigStepType.OTP_SMS,
        GwConfigStepType.SELFIE_CAPTURE,
        GwConfigStepType.VALIDATE,
        GwConfigStepType.RESULT,
    ];
    if (formConfig.steps === 'short') {
        flowConfig = [
            GwConfigStepType.DOCUMENT_TYPE_SELECT,
            GwConfigStepType.DOCUMENT_CAPTURE,
            GwConfigStepType.SELFIE_CAPTURE,
            GwConfigStepType.VALIDATE,
            GwConfigStepType.RESULT,
        ];
    }
    // formConfig.steps = [
    //     [
    //         'UPLOADS',
    //         {
    //             items: [
    //                 {
    //                     key: 'document',
    //                     label: 'Comprobantesss de domicilio',
    //                 },
    //             ],
    //         },
    //     ],
    // ];
    if (Array.isArray(formConfig.steps) && formConfig.steps.length > 0) {
        flowConfig = [...formConfig.steps, GwConfigStepType.RESULT];
    }

    // flowConfig = [
    //     [GwConfigStepType.DOCUMENT_CAPTURE, { validations: [{ minAge: 50 }] }],
    //     GwConfigStepType.VALIDATE,
    //     GwConfigStepType.RESULT,
    // ];

    // comment this only for debug purposes
    // flowConfig = [
    //     // GwConfigStepType.WELCOME,
    //     // GwConfigStepType.OTP_SMS,
    //     // GwConfigStepType.DOCUMENT_TYPE_SELECT,
    //     // GwConfigStepType.DOCUMENT_CAPTURE,
    //     // GwConfigStepType.PERSONAL_INFO,
    //     // GwConfigStepType.UPLOADS,
    //     // GwConfigStepType.OTP_SMS,
    //     GwConfigStepType.SELFIE_CAPTURE,
    //     // [GwConfigStepType.VALIDATE, { showSummary: false }],
    // ];

    const flow = flowConfig.map((sType: any) => {
        const type: GwConfigStepType = Array.isArray(sType) ? sType[0] : sType;
        const options = Array.isArray(sType) ? sType[1] : {};
        return {
            type,
            options: {
                ...options,
            },
        };
    });

    return flow.map((s) => {
        switch (s.type) {
            case GwConfigStepType.WELCOME:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[GwConfigStepType.WELCOME],
                        logoSrc: gwConfig?.uiConfig?.logoUrl,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowWelcomeOptions>;
            case GwConfigStepType.OTP_SMS:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[GwConfigStepType.OTP_SMS],
                        // countryAlpha2: clientDataConfig?.countryCode ? countriesMap[clientDataConfig?.countryCode]?.alpha2: undefined,
                        defaultCountyCode: clientDataConfig?.countryCode,
                        defaultPhone: defaultValues?.userPhone,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowOtpOptions>;
            case GwConfigStepType.OTP_EMAIL:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[GwConfigStepType.OTP_EMAIL],
                        ...s.options,
                        defaultEmail: defaultValues?.userEmail,
                    },
                } as GwConfigStep<GwFlowOtpOptions>;
            case GwConfigStepType.DOCUMENT_TYPE_SELECT:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[
                            GwConfigStepType.DOCUMENT_TYPE_SELECT
                        ],
                        defaultCountryCode: clientDataConfig?.countryCode,
                        availableDocuments: formConfig?.availableDocuments,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowDocumentTypeOptions>;
            case GwConfigStepType.DOCUMENT_CAPTURE:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[
                            GwConfigStepType.DOCUMENT_CAPTURE
                        ],
                        provider: defaultDocumentCaptureProvider,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowDocumentCaptureOptions>;
            case GwConfigStepType.PERSONAL_INFO:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[GwConfigStepType.PERSONAL_INFO],
                        availableDocuments: formConfig?.availableDocuments,
                        defaultDocumentCountry: clientDataConfig?.countryCode,
                        defaultCountryCode: clientDataConfig?.countryCode,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowPersonalInfoOptions>;
            case GwConfigStepType.UPLOADS:
                return { ...s } as GwConfigStep<GwFlowUploadsOptions>;
            case GwConfigStepType.SELFIE_CAPTURE:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[
                            GwConfigStepType.SELFIE_CAPTURE
                        ],
                        provider: defaultFaceCaptureProvider,
                        showSummary: !!clientDataConfig?.enableConfirmSelfie,
                        cropFactor: clientDataConfig?.cropFactor,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowFaceCaptureOptions>;
            case GwConfigStepType.VALIDATE:
                return {
                    ...s,
                    options: {
                        ...DEFAULT_STEP_OPTIONS[GwConfigStepType.VALIDATE],
                        showSummary: !!clientDataConfig?.showSummary,
                        ...s.options,
                    },
                } as GwConfigStep<GwFlowValidateOptions>;
            case GwConfigStepType.RESULT:
                return { ...s } as GwConfigStep<GwFlowResultOptions>;
            default:
                return { ...s } as GwConfigStep<GwConfigStepOptions>;
        }
    });
}
