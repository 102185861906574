import React from 'react';
import { Collapse, Modal } from 'antd';
import { debugDataWithModal } from './DebugComponentsPage';
import { GwFlowStepResult } from '../components/GwFlow';
import { GwConfigStepType } from 'gw-api/dist/types';
import GwFlowPersonalInfo from '../components/GwFlowPersonalInfo/GwFlowPersonalInfo';
import { GwFlowDocumentCaptureProviderResult } from 'gw-api/dist/types';
import { DocumentCaptureInfo } from 'gw-api/dist/types';
import { GwFlowOtpResult } from '../components/GwFlowOtp/GwFlowOtp';
import { normalizeUserInfo } from '../components/GwFlowDocumentCapture/GwFlowDocumentCaptureMicroblink';
import { GwFlowDocumentTypeSelectResult } from '../components/GwFlowDocumentTypeSelect/GwFlowDocumentTypeSelect';

export default DebugGwFlowPersonalInfo;
export type DebugGwFlowPersonalInfoProps = {};

function DebugGwFlowPersonalInfo({ ...rest }: DebugGwFlowPersonalInfoProps) {
    const onResult = (result: GwFlowStepResult) => {
        const { rawScanResult, ...rest } = result;
        debugDataWithModal('', {
            ...rest,
        });
    };
    const samples: {
        title: string;
        props: any;
    }[] = [
        {
            title: 'Default',
            props: {
                options: {
                    availableDocuments: [
                        'ID_CARD',
                        'PASSPORT',
                        // 'DRIVING_LICENSE',
                    ],
                    validations: [{ minAge: 18 }],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        // testing normalizeUserInfo from microblink capture
                        documentInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro\n\n\nPablo' },
                                lastName: { latin: 'Picapiedra\nMarmol' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguay' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                    [GwConfigStepType.DOCUMENT_TYPE_SELECT]: {
                        documentType: {
                            code: 'PASSPORT',
                        },
                        country: {
                            alpha3: 'URY',
                        },
                    } as GwFlowDocumentTypeSelectResult,
                },
            },
        },
        {
            title: 'Defaulting from previous otp',
            props: {
                options: {
                    fullFields: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                    [GwConfigStepType.OTP_EMAIL]: {
                        email: 'one@two.com',
                        status: 'success',
                        result: {
                            recipient: 'one@two.com',
                            verified: true,
                        },
                    } as GwFlowOtpResult,
                    [GwConfigStepType.OTP_SMS]: {
                        phone: '+59812345678',
                        status: 'success',
                        result: {
                            recipient: '+59812345678',
                            verified: true,
                        },
                    } as GwFlowOtpResult,
                },
            },
        },
        {
            title: 'Enable edit',
            props: {
                config: {},
                options: {
                    enableEdit: true,
                    validations: [{ minAge: 40 }],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                },
            },
        },
        {
            title: 'Full fields edit',
            props: {
                options: {
                    enableEdit: true,
                    fields: [],
                    fullFields: true,
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                },
            },
        },
        {
            title: 'Excluding ocr fields',
            props: {
                options: {
                    fields: [
                        { type: 'firstName', show: false },
                        { type: 'lastName', show: false },
                        { type: 'dateOfBirth', show: false },
                        { type: 'nationality', show: false },
                        { type: 'documentNumber', show: false },
                    ],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                },
            },
        },
        {
            title: 'Making ocr fields not required and not disabled',
            props: {
                options: {
                    fields: [
                        { type: 'firstName', required: false, disabled: false },
                        { type: 'lastName', required: false, disabled: false },
                        {
                            type: 'dateOfBirth',
                            required: false,
                            disabled: false,
                        },
                        {
                            type: 'nationality',
                            required: false,
                            disabled: false,
                        },
                    ],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 1985 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                },
            },
        },
        {
            title: 'Min age validation 18',
            props: {
                options: {
                    enableEdit: true,
                    validations: [{ minAge: 18 }],
                    defaultDocumentCountry: 'URY',
                    defaultDocumentType: 'ID_CARD',
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 2010 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                    } as GwFlowDocumentCaptureProviderResult,
                },
            },
        },

        {
            title: 'Conditional',
            key: 'conditional',
            props: {
                options: {
                    enableEdit: true,
                    // defaultCountryCode: 'URY',
                    // defaultDocumentCountry: 'URY',
                    // defaultDocumentType: 'ID_CARD',
                    autofillCountryOfResidence: true,
                    autofillCityOfResidence: true,
                    fields: [
                        {
                            type: 'firstName',
                            disabled: false,
                            show: false,
                        },
                        {
                            type: 'lastName',
                            disabled: false,
                            show: false,
                        },
                        { type: 'phone', show: false, required: true },
                        { type: 'email', show: false, required: true },
                        { type: 'dateOfBirth', show: false, required: true },
                        { type: 'documentType', show: false, required: true },
                        { type: 'documentNumber', show: false, required: true },
                        { type: 'nationality', show: false, required: true },
                        {
                            type: 'countryOfResidence',
                            show: false,
                            required: true,
                        },
                        {
                            type: 'cityOfResidence',
                            show: false,
                            required: true,
                        },
                        {
                            type: 'documentExpirationDate',
                            show: false,
                            required: true,
                        },
                        {
                            type: 'taxIdentificationNumber',
                            show: false,
                            required: true,
                        },
                        {
                            type: 'documentCountry',
                            show: true,
                            required: true,
                        },
                        {
                            type: 'cpfNumber',
                            show: {
                                '==': [{ var: 'documentCountry' }, 'BRA'],
                            },
                            required: true,
                        },
                    ],
                },
                onResult: onResult,
                onCancel: () => Modal.info({ title: 'canceled' }),
                onError: (error: any) => Modal.info({ title: error.message }),
                currentResult: {
                    [GwConfigStepType.DOCUMENT_CAPTURE]: {
                        userInfo: normalizeUserInfo(
                            {
                                firstName: { latin: 'Pedro' },
                                lastName: { latin: 'Picapiedra' },
                                dateOfBirth: { day: 20, month: 3, year: 2010 },
                                dateOfExpiry: { day: 20, month: 3, year: 2030 },
                                nationality: { latin: 'Uruguaya' },
                                documentNumber: { latin: '12223334' },
                            },
                            'DD/MM/YYYY'
                        ) as DocumentCaptureInfo,
                        documentInfo: {
                            firstName: 'PEDRO',
                            lastName: 'PICAPIEDRA',
                            dateOfBirth: '20/03/1985',
                            placeOfBirth: 'MONTEVIDEO (URUGUAY)',
                            dateOfExpiry: '26/05/2030',
                            dateOfIssue: '27/05/2020',
                            nationality: 'ESPAÑOLA',
                            nationalityAlpha3: 'ESP',
                            documentCode: 'PASSPORT',
                            documentNumber: 'XDD601284',
                            documentIssuer: 'SPAIN',
                            documentIssuerAlpha3: 'ESP',
                        },
                    } as GwFlowDocumentCaptureProviderResult,
                    [GwConfigStepType.OTP_EMAIL]: {
                        email: 'one@two.com',
                        status: 'success',
                        result: {
                            recipient: 'one@two.com',
                            verified: true,
                        },
                    } as GwFlowOtpResult,
                    [GwConfigStepType.OTP_SMS]: {
                        phone: '12345678',
                        status: 'success',
                        result: {
                            recipient: '+59812345678',
                            verified: true,
                        },
                    } as GwFlowOtpResult,
                },
            },
        },
    ];
    return (
        <Collapse
            destroyInactivePanel={true}
            defaultActiveKey={['conditional']}
        >
            {samples.map(({ title, key, props }, index) => {
                return (
                    <Collapse.Panel header={title} key={key || title}>
                        <GwFlowPersonalInfo {...props} />
                    </Collapse.Panel>
                );
            })}
        </Collapse>
    );
}
